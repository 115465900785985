var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_wrap"},[_c('h1',{staticClass:"h_none"},[_vm._v("商标管理系统")]),_c('h2',{staticClass:"h_none"},[_vm._v("蓝灯鱼商标管理、商标、流程化管理")]),_c('img',{staticClass:"h_none",attrs:{"src":"/images/result_show_logo.png","alt":""}}),_vm._m(0),_vm._m(1),_c('section',{staticClass:"subject"},[_vm._m(2),_vm._m(3),_c('el-popover',{staticClass:"contact item1",attrs:{"placement":"top","width":"350","trigger":"hover"}},[(_vm.isCN)?_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":"/images/index/mpian.jpg","alt":""}}):_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":"/images/index/mpian_en.png","alt":""}}),_c('el-button',{attrs:{"slot":"reference","type":"primary"},slot:"reference"},[_vm._v("联系我们")])],1)],1),_c('section',{staticClass:"sub_part"},[_vm._m(4),_c('div',{staticClass:"subject patent"},[_vm._m(5),_vm._m(6),_c('el-popover',{staticClass:"contact item2",attrs:{"placement":"top","width":"350","trigger":"hover"}},[(_vm.isCN)?_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":"/images/index/mpian.jpg","alt":""}}):_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":"/images/index/mpian_en.png","alt":""}}),_c('el-button',{attrs:{"slot":"reference","type":"primary"},slot:"reference"},[_vm._v("联系我们")])],1)],1)]),_c('footers'),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"close-on-click-modal":false,"custom-class":"login_dialog","before-close":_vm.closeEvent},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('login',{attrs:{"dialog-close":_vm.dialogClose},on:{"loginDialog":_vm.loginDialog,"dialogCloseBool":_vm.dialogCloseBool}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index_banner"},[_c('i',{staticClass:"index_banner_light"}),_c('i',{staticClass:"index_banner_lightT"}),_c('div',{staticClass:"index_banner_title"},[_c('p',[_vm._v("数智平台")]),_c('p',[_c('span',[_vm._v("智慧知产、数据互连")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"top"},[_c('span',[_vm._v(" 智慧园区 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subject_left"},[_c('img',{staticStyle:{"top":"-25px"},attrs:{"src":require("../../assets/images/smartPark/smartPark.png"),"alt":""}}),_c('div',{staticClass:"subject_left_rud"},[_c('p',{staticClass:"text_item1"},[_vm._v(" 产品优势： ")]),_c('div',{staticClass:"text_item2"},[_vm._v(" 基于大数据和AI技术"),_c('br'),_vm._v(" 平台交互、方便快捷"),_c('br'),_vm._v(" 专注知识产权管理和服务"),_c('br'),_vm._v(" 全面整合供需方业务需求 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subject_right"},[_c('div',{staticClass:"subject_right_title"},[_vm._v(" 实时监控园区企业知识产权动态 ")]),_c('ul',[_c('li',[_vm._v(" 蓝灯鱼智慧园区管理平台是一款实时监控园区企业知识产权动态，园区知识产权保护态势，可用于通过分析园区企业知识产权情况， 为园区知识产权服务提供管理工具。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top patent"},[_c('span',[_vm._v(" 企业网盘 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subject_left"},[_c('img',{attrs:{"src":require("../../assets/images/smartPark/part2.png"),"alt":""}}),_c('div',{staticClass:"subject_left_rud"},[_c('p',{staticClass:"text_item1"},[_vm._v(" 产品优势： ")]),_c('div',{staticClass:"text_item2"},[_vm._v(" 共享协作，权限灵活"),_c('br'),_vm._v(" 便捷搜索，快速定位"),_c('br'),_vm._v(" 加密存储，数据安全"),_c('br'),_vm._v(" 个性定制，实时追溯 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subject_right"},[_c('div',{staticClass:"subject_right_title"},[_vm._v(" 面向IP代理机构和企业，文件云管理系统 ")]),_c('ul',[_c('li',[_vm._v(" 蓝灯鱼「企业网盘」是一款文件云管理可定制的产品，具有安全便捷、 共享协作、灵活接入、实时同步的特点。 ")]),_c('li',[_c('span',{staticClass:"characteristics"},[_vm._v("自身特点：")]),_vm._v("整合「文件」能力赋能给机构或企业现有信息系统，如邮件、OA、ERP等，成为企业文件入口沉淀各系统内文件；实现现有业务系统与网盘文件自动对接、存储及查询； ")])])])
}]

export { render, staticRenderFns }